<template>
  <div class="overview">
    <b-container class="main-gradient light" fluid>
      <b-row>
        <b-col no-gutters>
          <b-row>
            <b-col class="chart-container">
              <bar-chart
                :graphData="graphData"
                :monthKeys="monthKeys"
                :currency="'€'"
                :currencySymbol="'€'"
                :label="'Value'"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import barChart from '@/components/d3charts/bar-chart/index'

export default {
  components: {
    barChart
  },
  data () {
    return {
      graphData: {
        transactions: {
          first_month: '2019-01',
          last_month: '2020-02'
        },
        months: {
          '2019-01': 1100,
          '2019-02': 1300,
          '2019-03': 900,
          '2019-04': 800,
          '2019-05': 1200,
          '2019-06': 700,
          '2019-07': 900,
          '2019-08': 1100,
          '2019-09': 900,
          '2019-10': 1210,
          '2019-11': 1050,
          '2019-12': 1350,
          '2020-01': 1000,
          '2020-02': 800
        }
      },
      monthKeys: {
        '01': {
          full: 'january',
          short: 'jan'
        },
        '02': {
          full: 'february',
          short: 'feb'
        },
        '03': {
          full: 'march',
          short: 'mar'
        },
        '04': {
          full: 'april',
          short: 'apr'
        },
        '05': {
          full: 'may',
          short: 'may'
        },
        '06': {
          full: 'june',
          short: 'jun'
        },
        '07': {
          full: 'july',
          short: 'jul'
        },
        '08': {
          full: 'august',
          short: 'aug'
        },
        '09': {
          full: 'september',
          short: 'sep'
        },
        10: {
          full: 'october',
          short: 'oct'
        },
        11: {
          full: 'november',
          short: 'nov'
        },
        12: {
          full: 'december',
          short: 'dec'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-container {
    padding-top: 50px;
  }
</style>
